import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import userBookmarksQuery from '../../requests/queries/userBookmarks';
import addBookmarkMutation from '../../requests/mutations/addBookmark';
import removeBookmarkMutation from '../../requests/mutations/removeBookmark';

export function useBookmarkMutation(refetch) {
  const [addBookmark] = useMutation(addBookmarkMutation, {
    onCompleted: () => refetch()
  });

  const [removeBookmark] = useMutation(removeBookmarkMutation, {
    onCompleted: () => refetch()
  });

  return [addBookmark, removeBookmark];
}

export function useBookmarksQuery() {
  const { data = {} } = useQuery(userBookmarksQuery);
  const { me = {} } = data;

  const [bookmarks, updateBookmarks] = useState(me.bookmarks || []);

  return [bookmarks, updateBookmarks];
}
