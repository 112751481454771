import { useMutation } from '@apollo/client';

import addCommentMutation from '../../requests/mutations/addComment';
import editCommentMutation from '../../requests/mutations/editComment';
import deleteCommentMutation from '../../requests/mutations/deleteComment';

export function useCommentMutation(refetch) {
  const [addComment] = useMutation(addCommentMutation, {
    onCompleted: () => refetch()
  });

  const [editComment] = useMutation(editCommentMutation, {
    onCompleted: () => refetch()
  });

  const [deleteComment] = useMutation(deleteCommentMutation, {
    onCompleted: () => refetch()
  });

  return [addComment, editComment, deleteComment];
}
