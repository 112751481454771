import gql from 'graphql-tag';

export default gql`
  mutation editComment(
    $commentId: Int!
    $message: String!
  ) {
    editComment(
      message: $message
      commentId: $commentId
    ) {
      id
    }
  }
`;
