import gql from 'graphql-tag';
import ContentFields from '../fragments/content';

export default gql`
  mutation addBookmark(
    $contentUri: String!
  ) {
    addBookmark(
      contentUri: $contentUri
    ) {
      id
      content {
        ...ContentFields
      }
    }
  }
  ${ContentFields}
`;
