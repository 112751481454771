import gql from 'graphql-tag';

export default gql`
  query form(
    $xuid: String!
  ) {
    form(xuid: $xuid) {
      xuid
      name
      data
      type
      recipient

      formAnswers {
        xuid
        data
        config
        createdBy
      }
    }
  }
`;
