import gql from 'graphql-tag';

export default gql`
  query getUserListItem(
    $username: String!
  ) {
    getUserListItem(username: $username) {
      id
      value

      item {
        id
        title
        imageUrl
        description

        content {
          uri
        }

        list {
          id
          name
          type
        }
      }
    }
  }
`;
