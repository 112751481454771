import gql from 'graphql-tag';

export default gql`
  query getList(
    $name: String!
  ) {
    getList(
      name: $name
    ) {
      items {
        id
        title
        imageUrl
        category
        orderKey
        description

        content {
          uri
        }

        product {
          price
          rating
          shopUrl
          supplier
          oldPrice
          priceLevel
          prosAndCons
          supplierUrl
        }
      }
    }
  }
`;
