import gql from 'graphql-tag';

export default gql`
  mutation submitFormAnswer(
    $formAnswerXuid: String!
  ) {
    submitFormAnswer(
      formAnswerXuid: $formAnswerXuid
    ) {
      xuid
    }
  }
`;
