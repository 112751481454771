import Head from 'next/head';
import gql from 'graphql-tag';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const Video = dynamic(
  () => import('plaza-react-components/src/components/Video/Video.jsx')
);
const VideoList = dynamic(
  () => import('plaza-react-components/src/components/VideoList/VideoList.jsx')
);
const Article = dynamic(
  () =>
    import('plaza-react-components/src/components/redesign/Article/Article.jsx')
);
const MedicalList = dynamic(
  () =>
    import('plaza-react-components/src/components/MedicalList/MedicalList.jsx')
);
const ContentFeed = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign//ContentFeed/ContentFeed.jsx'
    )
);
const SearchWidget = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/SearchWidget/SearchWidget.jsx'
    )
);
const SearchFormBanner = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/SearchFormBanner/SearchFormBanner.jsx'
    )
);
const CategoryHeader = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/CategoryHeader/CategoryHeader.jsx'
    )
);
const CallToActionBanner = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/CallToActionBanner/CallToActionBanner.jsx'
    )
);
const CalendarCarousel = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/CalendarCarousel/CalendarCarousel.jsx'
    )
);
const ContentVerticalList = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/ContentVerticalList/ContentVerticalList.jsx'
    )
);
const CallToActionBannerRedesigned = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/CallToActionBanner/CallToActionBanner.jsx'
    )
);
const Custom404 = dynamic(() => import('./404'));

import useAds from '../lib/hooks/ads';
import Preloader from '../shared/preloader';
import { getItem, removeItem } from '../lib/localStorage';
import { useCommentMutation } from '../lib/hooks/comments';
import { useBookmarkMutation } from '../lib/hooks/bookmarks';
import { useAddSurveyAnswerMutation } from '../lib/hooks/surveys';
import { useChangeUserDataMutation } from '../lib/hooks/userData';
import { useAddUserEventMutation } from '../lib/hooks/userEvents';
import { useUserJoinGroupMutation } from '../lib/hooks/userGroups';
import { addGoogleStructuredData } from '../lib/googleStructuredData';
import { useSetUserListItemMutation } from '../lib/hooks/userListItems';
import {
  useAddFormAnswerMutation,
  useSubmitFormAnswerMutation
} from '../lib/hooks/forms';
import formQuery from '../requests/queries/form';
import contentQuery from '../requests/queries/content';
import getListQuery from '../requests/queries/getList';
import metaByPath from '../requests/queries/metaByPath';
import contentImageQuery from '../requests/queries/contentImage';
import getUserListItem from '../requests/queries/getUserListItem';
import medicalContents from '../requests/queries/medicalContents';
import userBookmarksQuery from '../requests/queries/userBookmarks';
import productCategoryQuery from '../requests/queries/productCategory';
import recentVideoContent from '../requests/queries/recentVideoContent';
import recentChildContentQuery from '../requests/queries/recentChildContent';
import popularChildContentQuery from '../requests/queries/popularChildContent';
import ContentFields from '../requests/fragments/content';
import header from '../config/components/header';
import config from '../config';

const categoriesWithMedicalList = [
  'wat-als-het-niet-lukt',
  'kwaaltjes-en-klachten',
  'complicaties-tijdens-de-bevalling',
  'kwaaltjes-en-ziektes'
];

const disabledPages = ['/duurzaam/babyuitzetlijst'];

const faqPages = [
  {
    uri: 'veelgestelde-vragen-faq',
    faqContentUri: 'veelgestelde-vragen'
  },
  {
    uri: 'alles-over-voeding-tijdens-de-zwangerschap',
    faqContentUri: 'eten-tijdens-de-zwangerschap'
  }
];

const quickFormTrackingCodes = {
  'een-overlijdensrisicoverzekering-wat-is-het-en-is-het-nodig': {
    aanvragen: ['insurance_post1', 'insurance_post1_aanvragen'],
    verzenden: ['insurance_post1', 'insurance_post1_verzenden'],
    vorige: ['insurance_post1', 'insurance_post1_vorige']
  },
  'de-beste-zorgverzekering-als-je-zwanger-bent': {
    aanvragen: ['insurance_post2', 'insurance_post2_aanvragen'],
    verzenden: ['insurance_post2', 'insurance_post2_verzenden'],
    vorige: ['insurance_post2', 'insurance_post2_vorige']
  },
  'de-financiele-kraamlijst-tweede-trimester': {
    aanvragen: ['insurance_post3', 'insurance_post3_aanvragen'],
    verzenden: ['insurance_post3', 'insurance_post3_verzenden'],
    vorige: ['insurance_post3', 'insurance_post3_vorige']
  },
  'de-financiele-kraamlijst-derde-trimester': {
    aanvragen: ['insurance_post4', 'insurance_post4_aanvragen'],
    verzenden: ['insurance_post4', 'insurance_post4_verzenden'],
    vorige: ['insurance_post4', 'insurance_post4_vorige']
  },
  'de-financiele-kraamlijst-eerste-trimester': {
    aanvragen: ['insurance_post5', 'insurance_post5_aanvragen'],
    verzenden: ['insurance_post5', 'insurance_post5_verzenden'],
    vorige: ['insurance_post5', 'insurance_post5_vorige']
  },
  'de-financiele-kraamlijst-na-de-geboorte': {
    aanvragen: ['insurance_post6', 'insurance_post6_aanvragen'],
    verzenden: ['insurance_post6', 'insurance_post6_verzenden'],
    vorige: ['insurance_post6', 'insurance_post6_vorige']
  },
  'kindje-gekregen-neem-je-verzekeringen-onder-de-loep': {
    aanvragen: ['insurance_post7', 'insurance_post7_aanvragen'],
    verzenden: ['insurance_post7', 'insurance_post7_verzenden'],
    vorige: ['insurance_post7', 'insurance_post7_vorige']
  },
  'zorgverzekering-voor-je-kind-zo-werkt-het': {
    aanvragen: ['insurance_post8', 'insurance_post8_aanvragen'],
    verzenden: ['insurance_post8', 'insurance_post8_verzenden'],
    vorige: ['insurance_post8', 'insurance_post8_vorige']
  },
  'wat-heb-je-nog-aan-je-zorgverzekering-na-je-zwangerschap': {
    aanvragen: ['insurance_post9', 'insurance_post9_aanvragen'],
    verzenden: ['insurance_post9', 'insurance_post9_verzenden'],
    vorige: ['insurance_post9', 'insurance_post9_vorige']
  },
  'zorgverzekering-na-je-bevalling': {
    aanvragen: ['insurance_post10', 'insurance_post10_aanvragen'],
    verzenden: ['insurance_post10', 'insurance_post10_verzenden'],
    vorige: ['insurance_post10', 'insurance_post10_vorige']
  },
  'overstappen-van-zorgverzekering-tijdens-je-zwangerschap-is-het-echt-nodig': {
    aanvragen: ['insurance_post11', 'insurance_post11_aanvragen'],
    verzenden: ['insurance_post11', 'insurance_post11_verzenden'],
    vorige: ['insurance_post11', 'insurance_post11_vorige']
  }
};

export default function ContentPage(props) {
  const router = useRouter();
  const { user, query, config, fvpDeals, isLogged, isPreviewMode } = props;

  const contentUri = query.contentUri[query.contentUri.length - 1];

  const callToActionProps = {
    actionTitleDesktop: `Wil je persoonlijk nieuws ontvangen
      en op de hoogte zijn van alle kanalen die je volgt?`,
    actionTitleMobile: [
      'Blijf op de hoogte!',
      'Ontvang nieuws speciaal voor jou!'
    ],
    buttonLabel: 'Registreren',
    buttonLink: config.SIGNUP_URL
  };

  const [fetchMetaByPath, { data: metadata = {} }] = useLazyQuery(metaByPath);

  const provider =
    (user.provider && user.provider.accountName) || query._p || getItem('_p');

  const {
    loading,
    data: contentData = {},
    refetch
  } = useQuery(contentQuery, {
    variables: { uri: contentUri, limit: 9, ...(provider && { provider }) }
  });

  const { data: bookmarksData = {}, refetch: refetchBookmarks } =
    useQuery(userBookmarksQuery);

  const adlibProperties = useAds(user, query, contentData.content);

  const faqPage =
    contentData.content &&
    faqPages.find(({ uri }) => uri === contentData.content.uri);

  const { data: faqData = {} } = useQuery(recentChildContentQuery, {
    variables: {
      limit: 100,
      uri: faqPage && faqPage.faqContentUri
    },
    skip: !faqPage
  });

  // https://team.linkorb.com/cards/7656
  const { data: formData = {} } = useQuery(formQuery, {
    variables: { xuid: 'z9VC7kPJSXukKFY-tk4sbg' }
  });

  const faqContentList =
    (faqData.content &&
      faqData.content.recentChildContent.filter((item) => item.tags[0])) ||
    [];

  const addUserEvent = useAddUserEventMutation();
  const addFormAnswer = useAddFormAnswerMutation();
  const userJoinGroup = useUserJoinGroupMutation();
  const changeUserData = useChangeUserDataMutation();
  const setUserListItem = useSetUserListItemMutation();
  const submitFormAnswer = useSubmitFormAnswerMutation();
  const addSurveyAnswer = useAddSurveyAnswerMutation(refetch);
  const [addBookmark, removeBookmark] = useBookmarkMutation(refetchBookmarks);
  const [addComment, editComment, deleteComment] = useCommentMutation(refetch);

  const [meta, fetchMeta] = useState(false);

  const [backButton, showBackButton] = useState(false);

  useEffect(() => {
    if (!meta && contentData.content && contentData.content.canonicalUrl) {
      fetchMeta(true);

      fetchMetaByPath({
        variables: { path: contentData.content.canonicalUrl }
      });
    }
  }, [meta, contentData.content, fetchMetaByPath]);

  useEffect(() => {
    if (getItem('show-back-button')) {
      showBackButton(true);
      removeItem('show-back-button');
    }

    trackCoursesPage(contentUri, addUserEvent);
    trackExpertsPage(contentUri, addUserEvent);
  }, []);

  useEffect(() => {
    if (!contentData.content) return;

    addGoogleStructuredData(
      contentData.content.videoUrl
        ? {
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            name: contentData.content.title,
            description: contentData.content.intro,
            thumbnailUrl: contentData.content.imageUrl,
            uploadDate: contentData.content.updatedAt,
            contentUrl: contentData.content.videoUrl
          }
        : {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            headline: contentData.content.title,
            image: [contentData.content.imageUrl],
            datePublished: contentData.content.createdAt,
            dateModified: contentData.content.updatedAt,
            ...(contentData.content.authorUser && {
              author: [
                {
                  '@type': 'Person',
                  name: contentData.content.authorUser.displayName,
                  jobTitle: contentData.content.authorUser.jobTitle,
                  url: contentData.content.authorUser.website
                }
              ]
            }),
            publisher: {
              name: contentData.content.author.displayName,
              url: `https://www.zwangerenportaal.nl${contentData.content.author.link}`
            }
          }
    );
  }, [contentData.content]);

  if (loading) return <Preloader />;

  if (!contentData?.content?.id || disabledPages.includes(router.asPath))
    return <Custom404 {...props} />;

  const { content = {} } = contentData;

  const shareUrls = {
    twitter: content.twitterUrl,
    whatsapp: content.whatsappUrl,
    facebook: content.facebookUrl
  };

  const contentPaths = [
    {
      uri: content.uri,
      title: content.title
    }
  ];

  const contentProps = {
    ...content,
    user,
    formData,
    useQuery,
    fvpDeals,
    formQuery,
    shareUrls,
    addComment,
    backButton,
    contentUri,
    addBookmark,
    editComment,
    contentPaths,
    getListQuery,
    addUserEvent,
    deleteComment,
    isPreviewMode,
    removeBookmark,
    addFormAnswer,
    userJoinGroup,
    faqContentList,
    changeUserData,
    adlibProperties,
    addSurveyAnswer,
    setUserListItem,
    getUserListItem,
    submitFormAnswer,
    callToActionProps,
    contentImageQuery,
    productCategoryQuery,
    metaByPath: metadata,
    loginUrl: config.LOGIN_URL,
    quickFormTrackingCodes: quickFormTrackingCodes[contentUri],
    bookmarks: bookmarksData.me && bookmarksData.me.bookmarks
  };

  if (content.videoUrl) return <VideoPage {...props} {...contentProps} />;

  const isMedicalListPage = contentProps.uri === 'medisch';

  if (contentProps.isCategory || isMedicalListPage)
    return (
      <CategoryPage
        {...props}
        {...contentProps}
        isMedicalListPage={isMedicalListPage}
      />
    );

  const relatedContent = contentProps.contributedContent.length
    ? contentProps.contributedContent
    : contentProps.related;

  const isCalendarPage = /^(baby-week|week)-\d+$/.test(content.uri);

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://djl1kaawo20l3.cloudfront.net" />

        <link
          rel="preload"
          as="image"
          href={content.imageUrl
            .replace('/w/', `/1700/`)
            .replace('/h/', `/450/`)}
          media="(max-width: 850px)"
        />

        {addMetadata(metadata, content)}
      </Head>

      {isCalendarPage && <CalendarCarousel uri={content.uri} />}

      <div className="contentPage" {...adlibProperties}>
        <div className="grid">
          <Article
            {...props}
            {...contentProps}
            breadCrumbs={content.canonicalUrl
              .slice(1)
              .split('/')
              .map((uri) => ({
                uri: `/${uri}`,
                title: uri
                  .replace(/-/g, ' ')
                  .replace(/^\w/, (chr) => chr.toUpperCase())
              }))}
          />

          {!isPreviewMode && (
            <LazyLoadComponent>
              <aside>
                {!!relatedContent.length && (
                  <ContentVerticalList
                    list={relatedContent}
                    title="Gerelateerde artikelen"
                  />
                )}

                <SearchWidget />
              </aside>
            </LazyLoadComponent>
          )}
        </div>

        <LazyLoadComponent>
          {!!faqContentList.length && (
            <MedicalList
              hideHeader={true}
              nextLink={props.nextLink}
              list={groupByFirstLetter(faqContentList)}
            />
          )}

          {!isPreviewMode && (
            <>
              {!!relatedContent.length && (
                <div>
                  <ContentFeed
                    highlightFirstItem
                    list={relatedContent.slice(0, 6)}
                    title="Gerelateerde artikelen"
                  />
                </div>
              )}

              {!isPreviewMode && !isLogged && (
                <CallToActionBannerRedesigned {...callToActionProps} />
              )}
            </>
          )}
        </LazyLoadComponent>
      </div>
    </>
  );
}

function VideoPage(props) {
  const {
    user,
    channel,
    isLogged,
    metaByPath,
    isPreviewMode,
    callToActionProps
  } = props;

  const { data = {}, loading } = useQuery(recentVideoContent);

  if (loading) return <Preloader />;

  const isSubscribed =
    user.username &&
    user.channelSubscriptions.find((item) => item.channel.id === channel.id);

  return (
    <>
      <Head>{addMetadata(metaByPath)}</Head>

      <div className="grid videoPage">
        <Video {...props} isSubscribed={isSubscribed} />

        {!isPreviewMode && (
          <aside>
            <VideoList
              view="sidebar"
              title="Gerelateerde video's"
              isPreviewMode={isPreviewMode}
              list={data.recentVideoContent}
            />

            <SearchFormBanner />
          </aside>
        )}
      </div>

      {!isPreviewMode && (
        <div>
          {!isLogged && <CallToActionBanner {...callToActionProps} />}

          <VideoList
            view="withoutSidebar"
            title="Gerelateerde video's"
            list={data.recentVideoContent}
          />
        </div>
      )}
    </>
  );
}

function CategoryPage(props) {
  const {
    uri,
    title,
    intro,
    author,
    layout,
    subTitle,
    imageUrl,
    isLogged,
    metaByPath,
    isCategory,
    canonicalUrl,
    isPreviewMode,
    faqContentList,
    adlibProperties,
    callToActionProps
  } = props;

  const isSubCategory = isCategory && !!props.parent?.uri;

  const HeadComponent = () => (
    <Head>
      <link rel="preconnect" href="https://djl1kaawo20l3.cloudfront.net" />

      {imageUrl && (
        <link
          rel="preload"
          as="image"
          href={imageUrl.replace('/w/', `/1700/`).replace('/h/', `/450/`)}
          media="(max-width: 850px)"
        />
      )}

      {addMetadata(metaByPath, {
        imageUrl,
        title,
        intro,
        uri,
        author
      })}
    </Head>
  );

  if (layout === 'layout-theme')
    return (
      <>
        <HeadComponent />

        <div className="layout-theme">
          <CategoryHeader
            title={title}
            imageUrl={imageUrl}
            description={intro}
          />

          <Article {...props} />
        </div>
      </>
    );

  if (layout === 'layout-introduction')
    return (
      <div
        className="layout-introduction"
        style={{
          background: `url('${props.imageUrl
            .replace('/w/', `/2400/`)
            .replace('/h/', `/1350/`)}') no-repeat center/cover`
        }}
      >
        <div className="layout-introduction-content">
          <Article {...props} />
        </div>
      </div>
    );

  return (
    <>
      <HeadComponent />

      {!props.isMedicalListPage && (
        <CategoryHeader
          title={title}
          imageUrl={imageUrl}
          description={subTitle}
          isSubCategory={isSubCategory}
        />
      )}

      {!props.isMedicalListPage && (
        <div className="grid" {...adlibProperties}>
          <Article
            {...props}
            {...(isSubCategory && {
              breadCrumbs: canonicalUrl
                .slice(1)
                .split('/')
                .map((uri) => ({
                  uri: `/${uri}`,
                  title: uri
                    .replace(/-/g, ' ')
                    .replace(/^\w/, (chr) => chr.toUpperCase())
                }))
            })}
          />

          {!isPreviewMode && (
            <LazyLoadComponent>
              <CategoryRelatedContent
                uri={uri}
                isSubCategory={isSubCategory}
                categoryName={title}
              />
            </LazyLoadComponent>
          )}
        </div>
      )}

      {!isPreviewMode && (
        <LazyLoadComponent>
          {!!faqContentList.length && (
            <MedicalList
              hideHeader={true}
              nextLink={props.nextLink}
              list={groupByFirstLetter(faqContentList)}
            />
          )}

          {props.isMedicalListPage ? (
            <MedicalListSection {...props} />
          ) : (
            <>
              {categoriesWithMedicalList.includes(uri.toLowerCase()) && (
                <MedicalListSection {...props} category={title} />
              )}
            </>
          )}

          {!isSubCategory && <CategoryRecentContent uri={uri} title={title} />}

          {!isPreviewMode && !isLogged && (
            <CallToActionBannerRedesigned {...callToActionProps} />
          )}
        </LazyLoadComponent>
      )}
    </>
  );
}

function CategoryRecentContent({ uri, title }) {
  const observerRef = useRef();
  const [limit, setLimit] = useState(9);

  const items = header({})
    .menu.find(({ link }) => link.slice(1) === uri.toLowerCase())
    ?.submenu.map((item) => item.link.split('/').pop());

  if (!items) return;

  const { data: recentChildContentData = {}, loading } = useQuery(
    gql`
        query {
            ${[uri, ...items].map(
              (item, idx) => `
                item${idx}: content(uri: "${item}") {
                  id
                  recentChildContent(
                    limit: 140
                  ) {
                    ...ContentFields
                  }
                }
              `
            )}
        }
        ${ContentFields}
    `,
    {
      ssr: false
    }
  );

  const list = Object.values(recentChildContentData)
    ?.filter(Boolean)
    .reduce((list, item) => {
      return list.concat(item.recentChildContent);
    }, [])
    .sort((a, b) => new Date(b.postedAt) - new Date(a.postedAt));

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];

      if (target.isIntersecting) setLimit((limit) => limit + 12);
    });

    if (observerRef.current) observer.observe(observerRef.current);

    return () => observerRef.current && observer.unobserve(observerRef.current);
  }, [list.length]);

  return (
    <>
      <div style={{ minHeight: '100vh' }}>
        {loading ? (
          <Preloader />
        ) : (
          <ContentFeed
            list={list.slice(0, limit)}
            highlightFirstItem
            title={`Recent in ${title}`}
          />
        )}

        <div ref={observerRef} />
      </div>
    </>
  );
}

function CategoryRelatedContent({ uri, categoryName, isSubCategory }) {
  const title = `${isSubCategory ? 'Recent' : 'Populair'} in ${categoryName}`;

  const { data = {}, loading } = useQuery(
    isSubCategory ? recentChildContentQuery : popularChildContentQuery,
    {
      variables: {
        limit: 100,
        uri
      },
      ssr: false
    }
  );

  if (loading) return <Preloader />;

  const contentList =
    data?.content?.popularChildContent ||
    (data?.content?.recentChildContent &&
      [...data.content.recentChildContent].sort(
        (a, b) => new Date(b.postedAt) - new Date(a.postedAt)
      ));

  return (
    <>
      <aside>
        {!!contentList?.length && (
          <div className="contentVerticalList-scrollable">
            <ContentVerticalList
              list={contentList}
              title={title}
              length={100}
            />
          </div>
        )}

        <SearchWidget />
      </aside>

      {isSubCategory && !!contentList?.length && (
        <div className="_mobile" style={{ margin: '-80px -5% 90px -5%' }}>
          <ContentFeed
            list={contentList.slice(0, 6)}
            highlightFirstItem
            title={title}
          />
        </div>
      )}
    </>
  );
}

function MedicalListSection(props) {
  const { loading, data = {} } = useQuery(medicalContents);

  if (loading) return <Preloader />;

  const list = props.category
    ? (data.medicalContents || []).filter(
        (item) => item.parent.title === props.category
      )
    : data.medicalContents;

  if (!list.length) return null;

  return (
    <MedicalList list={groupByFirstLetter(list)} nextLink={props.nextLink} />
  );
}

function imageSrc(imageUrl, width, height) {
  if (!imageUrl) return;

  return imageUrl.replace('/w/', `/${width}/`).replace('/h/', `/${height}/`);
}

function addMetadata(metadata, content = {}) {
  return (
    metadata?.metaByPath && (
      <>
        <title>{metadata.metaByPath.title}</title>

        <meta name="description" content={metadata.metaByPath.description} />
        <meta name="keywords" content={metadata.metaByPath.keywords} />

        <meta property="og:image" content={content.imageUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={content.title} />
        <meta property="fb:app_id" content="456450208090095" />
        <meta property="og:description" content={content.intro} />
        <meta
          property="og:url"
          content={`https://${config.HOST}/${content.uri.toLowerCase()}`}
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="314" />
        <meta name="author" content={content.author?.displayName} />
      </>
    )
  );
}

function groupByFirstLetter(list) {
  return list.reduce((letters, item) => {
    const letter = item.title[0].toUpperCase();

    if (!letter.match(/[a-zA-Z]/)) return letters;

    if (letters[letter]) letters[letter].push(item);
    else letters[letter] = [item];

    return letters;
  }, {});
}

function trackCoursesPage(contentUri, addUserEvent) {
  switch (contentUri) {
    case 'zorgverzekeraars-die-onze-online-cursussen-wel-en-niet-vergoeden':
      return addUserEvent({
        variables: { type: 'courses-compensate', data: '{}' }
      });
    case '10-zwangerschaps-en-bevalcursussen-onbeperkt-en-online-bij-zwangerenportaalnl':
      return addUserEvent({
        variables: { type: 'courses-more-info', data: '{}' }
      });
  }
}

function trackExpertsPage(contentUri, addUserEvent) {
  if (
    [
      'kun-je-bij-een-kleine-baby-al-spreken-van-verwennen',
      'opvoeden-wanneer-begin-je-daarmee',
      'wat-mag-je-als-ouder-verwachten-van-een-kinderdagverblijf',
      'kinderopvang-zaken-om-op-te-letten',
      'moeder-worden-en-weer-aan-het-werk',
      'een-dagje-uit-het-leven-van-een-kraamverzorgende',
      'kraamzorg-en-het-voeden-van-je-baby',
      'hechting-tussen-jou-en-je-baby',
      'wat-is-kraamzorg-eigenlijk',
      'alles-over-kraamzorg',
      'osteopathie-bij-zwangeren',
      'osteopathie-bij-pasgeborenen',
      'wat-is-osteopathie',
      'bekkenbodem-check',
      'herstel-van-het-bekken-en-de-bekkenbodem-na-de-bevalling',
      'bekkeninstabiliteit',
      'de-bekkenbodem-tijdens-zwangerschap-en-bevalling',
      'bekkenbodem-en-buikspieroefeningen-na-de-bevalling',
      'chiropractie-bij-babys',
      'chiropractie-bij-zwangeren',
      'wat-is-chiropractie',
      'de-inwendige-echo',
      '3d-en-4d-echo-wat-zijn-de-verschillen',
      'liggingsecho-even-kijken-of-de-baby-goed-ligt-voor-de-bevalling',
      'pretechos-geen-medisch-onderzoek-maar-gewoon-omdat-het-leuk-is',
      'vitaliteitsecho-bevestiging-van-de-zwangerschap'
    ].includes(contentUri)
  )
    addUserEvent({
      variables: { type: `content:${contentUri}`, data: '{}' }
    });
}
