import gql from 'graphql-tag';
import ContentFields from '../fragments/content';

export default gql`
  query content($uri: String!, $limit: Int, $offset: Int, $provider: String) {
    content(uri: $uri) {
      id
      uri
      title
      intro
      isTag
      layout
      subTitle
      imageUrl
      videoUrl
      metadata
      postedAt
      updatedAt
      isCategory
      contentHTML
      canonicalUrl
      commentsEnabled

      tags {
        id
        uri
        title
      }

      related(limit: $limit, offset: $offset) {
        ...ContentFields
      }

      contributedContent {
        ...ContentFields
      }

      channel {
        id
        uri
        imageUrl
        displayName
        description
        contentCount
        numberOfFollowers
      }

      whatsappUrl: shareUrl(type: "whatsapp")
      facebookUrl: shareUrl(type: "facebook")
      twitterUrl: shareUrl(type: "twitter")

      comments {
        id
        message
        createdAt
        authorDisplayName

        user {
          username
          displayName
          profilePicture
        }

        comments {
          id
          message
          createdAt
          authorDisplayName

          user {
            username
            displayName
            profilePicture
          }
        }
      }

      authorUser {
        displayName
        website
        jobTitle
      }

      author {
        link
        imageUrl
        description
        displayName
      }

      authorUser {
        displayName
        website
        jobTitle
      }

      expert(provider: $provider) {
        link
        email
        website
        imageUrl
        subTitle
        description
        displayName

        channel {
          id
          uri
        }
      }

      verifier {
        link
        displayName
      }

      parent {
        uri
        title
      }
    }
  }
  ${ContentFields}
`;
