import gql from 'graphql-tag';

export default gql`
  mutation setUserListItem(
    $listItemId: Int!
  ) {
    setUserListItem(
      listItemId: $listItemId
    ) {
      id
    }
  }
`;
