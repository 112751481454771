import gql from 'graphql-tag';

export default gql`
  mutation deleteComment(
    $commentId: Int!
  ) {
    deleteComment(
      commentId: $commentId
    ) {
      id
    }
  }
`;
