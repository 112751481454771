import gql from 'graphql-tag';

export default gql`
  mutation updateFormAnswer(
    $data: String
    $formAnswerXuid: String!
  ) {
    updateFormAnswer(
      data: $data
      formAnswerXuid: $formAnswerXuid
    ) {
      xuid
    }
  }
`;
