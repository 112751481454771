import gql from 'graphql-tag';

export default gql`
  mutation addFormAnswer(
    $data: String!
    $formXuid: String!
    $recipient: String!
  ) {
    addFormAnswer(
      data: $data
      formXuid: $formXuid
      recipient: $recipient
    ) {
      xuid
    }
  }
`;
