import gql from 'graphql-tag';

export default gql`
  query content(
    $uri: String!
  ) {
    content(uri: $uri) {
      id
      imageUrl
    }
  }
`;
