import gql from 'graphql-tag';

export default gql`
  query productCategory(
    $code: String!
  ) {
    productCategory(
      code: $code
    ) {
      name
      products {
        price
        rating
        shopUrl
        supplier
        oldPrice
        imageUrl
        priceLevel
        prosAndCons
        supplierUrl
      }
    }
  }
`;

