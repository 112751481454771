import gql from 'graphql-tag';
import ContentFields from '../fragments/content';

export default gql`
  query content(
    $uri: String!
    $limit: Int
    $offset: Int
  ) {
    content(uri: $uri) {
      id

      popularChildContent(
        limit: $limit
        offset: $offset
      ) {
        ...ContentFields
      }
    }
  }
  ${ContentFields}
`;
