import { useMutation } from '@apollo/client';

import addFormAnswerMutation from '../../requests/mutations/addFormAnswer';
import updateFormAnswerMutation from '../../requests/mutations/updateFormAnswer';
import submitFormAnswerMutation from '../../requests/mutations/submitFormAnswer';

export function useAddFormAnswerMutation(refetch) {
  const [addFormAnswer] = useMutation(addFormAnswerMutation);

  return addFormAnswer;
}

export function useUpdateFormAnswerMutation(refetch) {
  const [updateFormAnswer] = useMutation(updateFormAnswerMutation);

  return updateFormAnswer;
}

export function useSubmitFormAnswerMutation(refetch) {
  const [submitFormAnswer] = useMutation(submitFormAnswerMutation, {
    onCompleted: () => refetch && refetch()
  });

  return submitFormAnswer;
}
