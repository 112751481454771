import gql from 'graphql-tag';
import ContentFields from '../fragments/content';

export default gql`
  query {
    me {
      bookmarks {
        id
        content {
          ...ContentFields
        }
      }
    }
  }
  ${ContentFields}
`;
