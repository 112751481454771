import { useMutation } from '@apollo/client';

import userJoinGroupMutation from '../../requests/mutations/userJoinGroup';
import userLeaveGroupMutation from '../../requests/mutations/userLeaveGroup';

export function useUserJoinGroupMutation(fn) {
  const [userJoinGroup] = useMutation(userJoinGroupMutation, {
    onCompleted: (data) => fn && fn(data)
  });

  return userJoinGroup;
}

export function useUserLeaveGroupMutation(fn) {
  const [userLeaveGroup] = useMutation(userLeaveGroupMutation, {
    onCompleted: (data) => fn && fn(data)
  });

  return userLeaveGroup;
}
