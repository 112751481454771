import gql from 'graphql-tag';

export default gql`
  mutation userJoinGroup($userGroupXuid: String!) {
    userJoinGroup(userGroupXuid: $userGroupXuid) {
      xuid
      uri
    }
  }
`;
