import gql from 'graphql-tag';

export default gql`
  query {
    medicalContents {
      title
      uri
      imageUrl

      parent {
        title
      }
    }
  }
`;
