import gql from 'graphql-tag';

export default gql`
  mutation addComment(
    $parentId: ID
    $message: String!
    $contentUri: String!
    $authorUserName: String!
  ) {
    addComment(
      message: $message
      parentId: $parentId
      contentUri: $contentUri
      authorUserName: $authorUserName
    ) {
      id
    }
  }
`;
