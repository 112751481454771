import { useEffect } from 'react';

import { getItem } from '../localStorage';

export default function useAds(user, query, content) {
  const phase = user.phase || query._ph || getItem('_ph');

  const provider =
    (user.provider && user.provider.accountName) || query._p || getItem('_p');

  const authenticated = user.username ? 'Y' : phase && provider ? 'Q' : 'N';

  useEffect(() => {
    if (content) {
      const adlibZone = document.querySelector('.adlib-zone');

      if (!adlibZone) return;

      const script = document.createElement('script');

      script.id = 'adlib';
      script.src = '/adlib.js';

      document.body.appendChild(script);
    }
  }, [content]);

  return (
    provider &&
    phase && {
      'data-adlib-properties': `provider:${provider},phase:${phase},authenticated:${authenticated}`
    }
  );
}
