import { useMutation } from '@apollo/client';

import setUserListItemMutation from '../../requests/mutations/setUserListItem';

export function useSetUserListItemMutation(refetch) {
  const [setUserListItem] = useMutation(setUserListItemMutation, {
    onCompleted: () => refetch && refetch()
  });

  return setUserListItem;
}
