import { useMutation } from '@apollo/client';

import userSurveyMutation from '../../requests/mutations/userSurvey';

export function useAddSurveyAnswerMutation(refetch) {
  const [addSurveyAnswer] = useMutation(userSurveyMutation, {
    onCompleted: () => refetch()
  });

  return addSurveyAnswer;
}
