import gql from 'graphql-tag';

export default gql`
  mutation userSurvey(
    $surveyId: Int!
    $answer: String!
    $username: String!
  ) {
    userSurvey(
      answer: $answer
      surveyId: $surveyId
      username: $username
    ) {
      id
    }
  }
`;
