import gql from 'graphql-tag';

export default gql`
  query {
    recentVideoContent {
      uri
      title
      imageUrl
      videoUrl
      publishedAt

      parent {
        title
      }

      channel {
        imageUrl
        displayName
        contentCount
        numberOfFollowers
      }
    }
  }
`;
